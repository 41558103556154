/* stylelint-disable */
.promotions {
  max-width: 1024px;
  margin: 30px auto;

  .promotion-block {
    display: flex;
    gap: 25px;

    .promotion-image {
      background-size: cover;
      width: 390px;
    }

    .promotion-text {
      display: flex;
      flex-direction: column;
      color: $gray-color;

      & h3 {
        font-weight: 500;
      }
    }

    p {

    }

    .offer-ends {
      color: #444d4d;
      font-size: 0.85em;

      span {
        font-size: 1em;
        font-weight: 500;
      }
    }

    .see-offer-conditions {
      color: $color3;
      margin-top: 15px;
      font-size: 0.9em;
      cursor: pointer;
    }

    .offer-conditions {
      font-size: 0.75em;
      color: $color4;
      display: none;
    }

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;

      .promotion-text {
        flex-direction: column;
        width: 85%;
      }

      .promotion-image {
        width: 85%;
        height: auto;
      }
    }
  }
}

.arrow-image {
  &.rotate {
    transform: rotate(180deg);
    transition: transform 0.2s linear;
  }
}
