/* stylelint-disable */
.window {
  padding: 20px 15px;
  font-size: 18px;
  width: 450px;
}

.store-name {
  font-weight: 500;
  font-size: 18px;
  color: $dark-gray-color;
  margin-bottom: 5px;
}

.store-address {
  font-size: 18px;
}

.store-phone {
  color: $orang-color;
  display: flex;
  align-items: center;
  margin: 10px 0;
  font-size: 18px;

  & img {
    margin-right: 10px;
  }
}

.store-delevery {
  font-size: 15px;
  margin: 10px 0;
}

.delevery-offert {
  color: green;
}

.store-hours {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  height: 130px;
  font-size: 15px;
  font-weight: 300;
}

.opening-hour-day {
  margin: 5px 10px 5px 0;
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  font-weight: 300;

  & span {
    width: 110px;
    text-align: start;
  }
}

.gm-ui-hover-effect {
  top: 1px !important;
  right: 15px !important;
  outline: none !important;
}

.gm-ui-hover-effect > span {
  width: 30px !important;
  height: 25px !important;
}

.store-autocomplete {
  max-width: 1024px;
  margin: 0 auto;

  @include respond (md) {
    margin: 0 15px;
  }
}

.store-legends {
  max-width: 1024px;
  margin: 0 auto;
  display: flex;
  justify-content: center;

  &__item {
    display: flex;
    align-items: center;
    padding: 3rem 2rem;
    font-size: 1.2rem;
    line-height: 2rem;
    color: $dark-gray-color;

    & img {
      margin-right: 10px;
    }
  }
}

// Location form

.form-locations {
  position: relative;

  &__group {
    min-width: 26em;
    padding-right: 2rem;
    margin: 2em 0 0;
    position: relative;
    top: 0;

    @include respond(md) {
      min-width: 100%;
    }
  }

  &__input {
    font-size: 14px;
    padding: 0.4em 0;
    display: block;
    width: 100%;
    border: none;
    border-bottom: 1px solid $dark-gray-color;
    background: none;
    border-radius: 0;
    transition: all 0.3s;
    color: $orang-color;

    &:focus {
      outline: none;
      border-bottom: 1px solid $orang-color;
    }

    &:focus:invalid {
      border-bottom: 1px solid red;
    }

    &::input-placeholder {
      color: $dark-gray-color;
      font-size: 14px;
    }
  }

  &__label {
    position: absolute;
    font-size: 12px;
    color: $orang-color;
    display: block;
    transition: all 0.1s;
    top: -40%;
  }

  &__input:placeholder-shown + &__label {
    opacity: 0;
    visibility: hidden;
    transform: translateY(40px);
  }

  &__condition {
    padding-top: 2rem;
    padding-bottom: 2rem;
    color: #fff;
    font-size: 0.7rem;
  }
}

// Locations searche

.store-list {
  color: #4b5356;

  @include respond(md) {
    display: flex;
    justify-content: center;
  }
}

.store-list-item {
  display: flex;
  justify-content: space-between;
  padding: 2rem 0;

  @include respond (md) {
    flex-direction: column;
    align-items: center;
    width: 80%;
  }
}

.store-details {
  display: flex;
  color: $dark-gray-color;

  @include respond(md) {
    width: 100%;
  }

  &__pin {
    margin-right: 0.5rem;
  }

  &__info{

  }

  &__store-name {
    font-size: 1.25rem;
    color: $dark-gray-color;
    font-weight: 500;
    margin-bottom: 1.12rem;
  }

  &__telephone {
    margin-top: 2rem;
    font-weight: 300;
  }

  .telephone-hint {
    color: $orang-color;
    font-weight: 600;
  }
}

.store-details__address__direction-link {
  color: $orang-color;
  font-size: 0.75rem;
}

.toggle-hours {
  z-index: 2;
  color: $dark-gray-color;
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;
  width: 15rem;
  background: none;
  border: none;
  text-align: left;
  height: fit-content;

  &:focus {
    outline: none;
  }

  &__text {
    z-index: -1;
  }

  & img {
    transform: rotate(0deg);
    transition: transform 0.2s linear;

    &.rotate {
      transform: rotate(180deg);
      transition: transform 0.2s linear;
    }
  }
}

.dropdown-arrow {
  z-index: -1;
}

.ordering-information {
  display: flex;
  flex-direction: column;
  text-align: center;

  @include respond(md) {
    width: 100%;
  }
}

.opening-hours {
  @include respond(md) {
    margin: 20px 0;
    width: 100%;
    margin-left: 60px;
  }
}

.delivery-information {
  display: flex;
  align-content: center;
  margin-top: 15px;

  & span {
    height: fit-content;
    align-self: center;
    font-size: 0.75rem;
    margin-left: 10px;
  }

  & img {
    height: 80%;
    align-self: center;
  }
}

// store name list

.store-name-list {
  max-height: 100px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 0.8em;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $orang-color;
  }

  &__item {
    display: block;
    color: $dark-gray-color;
    text-transform: uppercase;
    cursor: pointer;

    &:hover {
      background-color: $orang-color;
    }
  }
}

.days {
  display: none;
}

.active {
  display: block;
}
