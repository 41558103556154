/* stylelint-disable */
.promo-block {
    min-height: 700px;
    width: 100%;
    padding: 100px 0 60px;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media screen and (max-width: 1576px) {
        background-position: 50% 0;
    }
    @media screen and (max-width: 1199px) {
        min-height: 550px;
    }
    @media screen and (max-width: 767px) {
        background-size: 180%;
    }

    &--heading {
        font-size: clamp(57px, 6.25vw, 250px) !important;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: 1.25vw;
        -webkit-text-stroke: 3px #ffffff;
        text-align: justify;
        &::after {
            content: "";
            display: inline-block;
            width: 100%;
        }
        @media screen and (min-width: 650px) and (max-width: 1199px) {
            font-size: max(67px, min(10.55vw, 250px)) !important;
            letter-spacing: 1.25vw;
        }
        @media screen and (max-width: 649px) {
            letter-spacing: 3vw;
            font-size: max(57px, min(21.5vw, 250px)) !important;
        }
        color: transparent;
        white-space: nowrap;
        overflow: hidden;
    }
    &--btn {
        border: 2px solid $dark-gray-color;
        color: $dark-gray-color;
        font-weight: 600;
        background: transparent;
        &:hover {
            background-color: #ffffff29;
        }
    }
    &--text {
        margin-bottom: 32px;
        margin-top: 200px;
        @media screen and (min-width: 650px) and (max-width: 768px) {
            margin-top: 150px;
        }
        @media screen and (max-width: 649px) {
            margin-top: 30px;
        }
        * {
            font-size: clamp(16px, 2vw, 20px);

            i {
                font-size: clamp(13px, 2vw, 15px);
                font-style: normal;
            }
        }

    }
    .container {
        max-width: 1054px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }
    &--badge {
        position: relative;
        z-index: 2;
        width: clamp(134px, 13.2vw, 200px);
        height: auto;
        left: 36px;
        bottom: -20px;
    }
    &--logo {
        width: clamp(200px, 20vw, 300px);
        height: auto;
        border-radius: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        &--image {
            width: clamp(70px, 7.166vw, 110px);
            height: auto;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 1;
        }
        &--text {
            width: 100%;
            height: 100%;
        }
    }
    .pb-20px {
        padding-bottom: 20px;
    }
    &--logos--container {
        @media screen and (max-width: 767px) {
            // to keep badge and logo in the center
            left: -18px;
        }
    }
    .overflow-hidden {
        overflow: hidden;
    }
}