/* stylelint-disable */
.video-block {
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    padding: 120px 0;
    @media screen and (max-width: 991px) {
        background-position: left;
    }
    .container {
        max-width: 1054px;
    }
    &--title {
        color: #FFFFFF;
        max-width: 640px;
        margin-bottom: 38px;
    }
    &--text {
        color: #FFFFFF;
        margin-bottom: 38px;
        max-width: 640px;
    }
    &--cta {
        font-size: clamp(18px, 2.5vw, 40px);
        text-transform: uppercase;
        color: #FFFFFF;
        margin-bottom: 38px;
        max-width: 480px;
    }
    &--btn {
        border: 2px solid #FFFFFF;
        color: #FFFFFF;
        font-weight: 600;
        &:hover {
            background-color: #ffffff29;
            color: #FFFFFF;
        }
        &:focus {
            outline-color: transparent;
            color: #FFFFFF;
        }
    }
    &--video {
        position: relative;
        width: 100%;
        padding-top: 56.25%; /* This is the magic! It creates a space with a 16:9 aspect ratio. */
        overflow: hidden;
        margin-top: 100px;
        @media screen and (max-width: 991px) {
            margin-top: 75px;
        }
        iframe {
            position: absolute;
            top: 0;
            left: 0;
            border: none;

            /* Extend it beyond the viewport... */
            width: 300%;
            height: 100%;
            /* ...and bring it back again */
            margin-left: -100%;

        }
        #customPlayPauseBtn {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            padding: 13px 3px;
            background-color: white;
            color: #6fc38a;
            border: none;
            cursor: pointer;
            border-radius: 30px;
            width: 80px;
            transition: opacity 0.3s linear 1s;
            z-index: 100;
            img {
                width: 30px;
            }
            &.hide {
                opacity: 0;
            }
        }
    }
}