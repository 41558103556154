.button {
  &,
  &:link,
  &:visited {
    font-size: 0.9rem;
    text-decoration: none;
    padding: 0.75rem 1.75rem;
    border-radius: 2rem;
    cursor: pointer;
    display: inline-block;
  }

  &--default {
    border: none;
    margin: 0 auto 2em;
    z-index: 100;
    background: #fff;
    color: #f57b20;

    &:hover {
      background-color: #f5f5f2;
      color: $orang-color;
    }
  }

  &--grey {
    background-color: #cecfd0;
    color: #fff;
    border: none;
  }

  &--orange {
    color: #f57b20;
    border: 2px solid #f57b20;
    border-radius: 2rem;
    transition: all 0.2s;
    flex: none;

    &:hover {
      color: #fff;
      background-color: #f57b20;
    }
  }
}
