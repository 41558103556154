* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ul,
ol {
  margin: 0;
}

html {
  font-size: 100%;

  @include respond(md) {
    font-size: 90%;
  }

  @include respond(sm) {
    font-size: 73%;
  }
}

body {
  font-family: Montserrat, sans-serif;
  background-color: #f5f5f2;
  color: $dark-gray-color;
}

.main-container {
  overflow-x: hidden;
}

a {
  background-color: transparent;
  font-size: inherit;
  color: inherit;
  text-decoration: none;

  &:focus,
  &:hover,
  a:active {
    text-decoration: none;
    color: inherit;
  }
}

p {
  margin: 0;
}

h2 {
  margin: 0;
}
