.menu-and-nutrition-header {
  position: relative;
  text-align: center;

  &__image {
    height: 350px;
    background-repeat: no-repeat;
    background-position: 50% 100%;
    margin: 0 auto;
  }

  &__title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 3.1em;
    font-weight: 500;
    text-shadow: 0 0 20px rgb(0 0 0 / 70%);

    & p {
      margin: 0;
    }
  }
}

.menu-and-nutrition-body {
  padding: 3.8rem 0;
  display: flexbox;
  display: flex;
  flex-direction: column;
  color: #4b5356;
  max-width: 1024px;
  margin: 0 auto;
}

.menu-and-nutrition-content {
  text-align: center;

  &__title {
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 1.25rem;
  }
}

// .gift-card {
//     width: 48%;

//     @include respond(md){
//         width: 100%;
//         padding:0 20px ;
//     }

//     margin-bottom: 50px;

//     &__image {
//         width: 100%;
//     }

//     &__content {
//         background-color: #fff;
//         padding: 5%;
//         height: 200px;
//         display: flex;
//     }
// }

.card-content-box {
  align-self: end;
  padding-top: 10px;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-right {
  margin-right: 2%;
}

.card-left {
  margin-left: 2%;
}

.menu-and-nutrition-content .menu-btn {
  display: flex;
  flex-direction: column;

  @include respond(md) {
    padding: 0 20px;
  }

  a {
    margin-bottom: 15px;
    text-decoration: underline;
  }
}

.content-menu {
  max-width: 1024px;
  margin: 0 auto;
  text-align: center;

  &__title {
    color: #444d4d;
    font-size: 1.2rem;
  }
}

.btn-menu {
  display: block;
  width: 100%;
  text-decoration: underline !important;
}
