/* stylelint-disable */
.image-grid-block {
    &--content {
        //aspect-ratio: 1 / 1;
        position: relative;
        width: 100%;
        overflow: hidden;
        &:before {
            content: "";
            display: block;
            padding-top: 100%;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
        &--text {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #FFFFFF;
            text-align: center;
            width: 100%;
            max-width: calc(100% - 40px);
            p, a, li {
                font-family: Montserrat, sans-serif;
                font-size: clamp(14px, 1.25vw, 20px);
                font-style: normal;
                font-weight: 500;
                letter-spacing: -0.2px;
            }
            * {
                color: inherit;
            }
        }
    }
    &-margin-True {
        max-width: 1024px;
        margin: 2em auto;
        position: relative;
    }
}