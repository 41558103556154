/* stylelint-disable */
.template-landing-page {
    .navigation {
        background: #212322;
    }
    .second-nav, .white-space {
        display: none;
    }
    .newsletter {
        padding: 2rem;
        background: #F03C53;
        &__content {
            flex-direction: column;
            &__infoText {
                text-transform: uppercase;
            }
        }
        .form__group {
            margin: 10px 0 0;
        }
        .form__label {
            color: #FFFFFF;
        }
        .form__input {
            border-color: #FFFFFF;
        }
        &:focus {
            border-color: #fff;
        }
        .submit-button-container {
            align-items: start;
        }
        .form__error-message.show-error {
            color: #FFFFFF;
        }
        .button--grey {
            background-color: transparent;
            border: 2px solid #ffffff;
            &:focus {
                outline-color: white;
            }
            &:hover {
                background-color: rgba(255,255,255,.1607843137);
            }
        }
        .custom-checkbox+*::before {
            border: 2px solid #ffffff;
        }
        .user-agreement-label {
            color: #ffffff;
        }
    }
    .footer-wrapper {
        background: #212322;
        .footer__link, .footer__address, .footer__copyright {
            color: #FFFFFF;
        }
    }
}