/* stylelint-disable */
.agreement-content {
  max-height: 300px;
  overflow: auto;
  padding: 10px;
  border: 1px solid #f1f1f1;
}

.user-agreement-label {
  font-size: 0.9rem;
  color: #cecfd0;
}

.user-agreement-contact-label {
  color: #4b5356;
  font-size: 0.8rem;
  display: inline;
}

.custom-checkbox {
  width: 20px;
  height: 20px;
  vertical-align: middle;

  &:checked {
    background-color: #ed7703;
    border-color: #ed7703;
    accent-color: #ed7703;
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem rgb(255 165 0 / 25%);
  }
}

.custom-checkbox {
  display: none;  /* hide the default checkbox */
}

.custom-checkbox + *::before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 8px;
  vertical-align: middle;
  background-color: transparent;
  border: 2px solid #999;
  cursor: pointer;
}

.custom-checkbox:checked + *::before {
  background-color: #ed7703;
  border-color: #ed7703;
}

.custom-checkbox:checked + *::after {
  content: "";
  display: inline-block;
  width: 7px;
  height: 13px;
  border: solid #fff;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
  position: absolute;
  top: 6px;
  left: 22px;
}

.custom-checkbox:focus + *::before {
  box-shadow: 0 0 0 0.2rem rgb(255 165 0 / 25%);
}

#newsletterSubmitButton, #contactFormSaveButton {
    &:disabled {
        cursor: not-allowed;
    }
}
#contactAgreementModal a *, #newsletterAgreementModal a * {
    font-size: 1rem;
    font-weight: 500;
    color: #ed7a20;
}