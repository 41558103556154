/* stylelint-disable */
.content-header {
  background-color: $color1;

  .content-header-content {
    min-height: 297px;

    @include respond(md) {
      min-height: 220px;
    }

    @include respond(sm) {
      min-height: 150px;
    }

    @include respond(xs) {
      min-height: 100px;
    }

    text-shadow: 0 0 20px rgb(0 0 0 / 70%);
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    max-width: 1024px;
    margin: 0 auto;

    h1 {
      font-size: 3.1em;
      font-weight: 500;
      color: white;
      text-align: center;
    }
  }
}
