/* stylelint-disable */
.main-container {
}

.banner-container {
  max-width: 1024px;
  margin: 0 auto;
  position: relative;

  & img {
    width: 100%;
  }

  & a {
    position: absolute;
    bottom: 0;
    right: 50%;
    transform: translateX(50%);

    & p {
      @include respond(sm) {
        font-size: 1.2rem;
      }
      @include respond(xs) {
        font-size: 0.8rem;
      }
    }

    & button {
      @include respond(sm) {
        margin-bottom: 0.5rem;
        padding: 0.2rem 0.8rem;
      }
      @include respond(xs) {
        margin-bottom: 0.3rem;
        padding: 0rem 0.5rem;
      }
    }
  }
}

.featured {
  padding-bottom: 5rem;

  @include respond(md) {
    padding: 0;
  }
}

.panel {
  max-width: 1024px;
  margin: 0 auto;
  padding: 4em 0 0;

  @include respond(md) {
    padding: 2em 0 0;
  }

  &__header {
    color: $dark-gray-color;
    line-height: 0.7;
    text-align: center;

    @include respond(md) {
      margin-bottom: 0;
    }

    & h2 {
      display: inline-block;
      font-size: 1.375rem;
      font-weight: 400;
      position: relative;
      text-transform: uppercase;
      white-space: nowrap;

      @include respond(md) {
        font-size: 1.1rem;
      }

      &::before {
        content: "";
        border-bottom: 2px solid #4b5356;
        position: absolute;
        height: 0.3125rem;
        right: 100%;
        margin-right: 1em;
        top: 25%;
        width: 7.8rem;

        @include respond(md) {
          width: 5.5rem;
        }

        @include respond(sm) {
          width: 2.5rem;
        }

        @include respond(xs) {
          width: 1.5rem;
        }
      }

      &::after {
        content: "";
        border-bottom: 2px solid #4b5356;
        position: absolute;
        height: 0.3125rem;
        left: 100%;
        margin-left: 1em;
        top: 25%;
        width: 7.8rem;

        @include respond(md) {
          width: 5.5rem;
        }

        @include respond(sm) {
          width: 2.5rem;
        }

        @include respond(xs) {
          width: 1.5rem;
        }
      }
    }
  }
}

.featured {
  &__list {
    display: flexbox;
    display: flex;
    flex-wrap: wrap;

    &__product {
      max-width: calc(100% / 3);
      @include respond(md) {
        max-width: 95%;
        margin: 0 auto;
      }
      -ms-flex-positive: 1;
      flex-grow: 1;
      text-align: center;
      min-width: 6.5rem;
    }
  }
}

.product {
  display: flexbox;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 1rem;
  text-align: center;
  -ms-flex-pack: justify;
  justify-content: space-between;

  &__body {
    display: flexbox;
    display: flex;
    flex-direction: column;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -ms-flex-align: center;
    align-items: center;

    & img {
      margin-top: 3.5rem;

      @include respond(md) {
        margin-top: 1rem;
      }

      width: 100%;
      -ms-flex-negative: 0;
      flex: 1;
      @media (min-width: 850px) {
        max-width: 309px;
        max-height: 309px;
      }

    }

    &__title {
      padding-bottom: 0.25rem;
      line-height: 1.5em;
      margin: 1em 0;
      font-size: 1.25rem;
      font-weight: 500;
      color: $dark-gray-color;
      height: 53px;

      @include respond(md) {
        height: min-content;
      }
    }
  }

  &__menu-link {
    font-size: 1rem;
    font-weight: 500;
    color: $orang-color;

    &:hover {
      opacity: 0.7;
      color: $orang-color;
    }
  }
}

.content {
  background: linear-gradient(#f47b20, #f47b20 442px, #f5f5f2 0);
  position: relative;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;

  @include respond(md) {
    background-size: 100% 100%;
    padding-bottom: 30px;
  }

  &__title {
    text-align: center;
    text-transform: uppercase;
    font-size: 1.5rem;
    font-weight: 500;
    color: #fff;
    max-width: 36rem;
    margin: 0 auto;
    padding: 2.5rem 1rem 1.5rem;

    @include respond(md) {
      font-size: 1rem;
    }
  }

  &__image-container {
    display: flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;

    @include respond(md) {
      flex-direction: column;
    }

    &__mobile-devices-wrapper {
      width: 630px;

      @include respond(md) {
        width: auto;
      }

      display: flexbox;
      display: flex;
      -ms-flex-pack: center;
      justify-content: center;
      -ms-flex-align: center;
      align-items: center;
      overflow: hidden;

      & img {
        width: 100%;
      }
    }

    &__buttonsContainer {
      display: flexbox;
      display: flex;
      flex-direction: column;

      & .apple-store img {
        width: 155px;
        margin-bottom: 22px;

        @include respond(md) {
          width: 140px;
          margin-bottom: 10px;
        }

        @include respond(sm) {
          width: 100px;
          margin-bottom: 10px;
        }
      }

      & .play-store img {
        width: 155px;
      }
    }
  }
}

.promotion {
  background: #fff;

  &__list {
    display: flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 3em 1.35em 4em;

    &__promo {
      width: 33.33333%;

      @include respond(md) {
        width: 95%;
      }

      -ms-flex-positive: 1;
      flex-grow: 1;
      margin: 0 0.65em;
    }
  }
}

.promo {
  display: flexbox;
  display: flex;
  flex-direction: column;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center;
  height: auto;

  &__image img {
    max-width: 100%;
    max-height: 100%;
  }

  &__link {
    color: #f57b20;
    font-size: 1rem;
    font-weight: 500;
    text-align: center;
    -ms-flex-item-align: center;
    grid-row-align: center;
    align-self: center;
    padding-top: 2rem;
  }
}

.about-us {
  padding-bottom: 5rem;
  padding-top: 1px;

  &__list {
    display: flexbox;
    display: flex;
    margin: 0 auto;
    -ms-flex-pack: center;
    justify-content: center;
    padding-top: 3.5rem;

    &__story {
      width: 33.33333%;
      -ms-flex-positive: 1;
      flex-grow: 1;
      margin: 0 1.5rem;
      text-align: left;
      min-width: 6.5rem;
    }
  }
}

.story {
  display: flexbox;
  display: flex;
  flex-direction: column;
  text-align: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  height: 100%;

  &__body {
    display: flexbox;
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    -ms-flex-align: center;
    align-items: center;

    & img {
      margin-top: 2rem;
      width: 100%;
    }

    &__title {
      padding-bottom: 0.25rem;
      line-height: 1.5em;
      margin: 1em 0;
      font-size: 1.25rem;
      font-weight: 500;
      color: $dark-gray-color;
    }

    &__description {
      margin-bottom: 0.75em;
      line-height: 1.5rem;
      font-size: 1rem;
      width: 100%;
    }
  }

  &__menu-link {
    color: $orang-color;
    font-size: 1rem;
    font-weight: 500;
  }
}

// Home page carousel

.owl-carousel {
  margin: 20px 0;
  @media (min-width: 850px) {
    display: none !important;
  }
}

.owl-dots {
  display: flex;
  justify-content: center;
  gap: 4px;
  .owl-dot {
    border: 1px solid #f57b20 !important;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    &:focus {
      outline: none !important;
    }

    &.active {
      background-color: #f57b20 !important;
    }
  }
}
