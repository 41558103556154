.error-page-container {
  max-width: 1024px;
  margin: 0 auto;
  text-align: center;
  height: calc(100vh - 500px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  align-items: center;
  padding: 0 20px;

  @media screen and (max-width: 576px) {
    height: calc(100vh - 350px);
  }

  .error-img {
    min-height: 200px;
  }

  .error-heading h2 {
    display: inline-block;
    font-size: 1.375rem;
    font-weight: 400;
    position: relative;
    text-transform: uppercase;
    white-space: nowrap;
  }

  .error-heading h2::before {
    content: "";
    border-bottom: 2px solid #4b5356;
    position: absolute;
    height: 0.3125rem;
    right: 100%;
    margin-right: 1em;
    top: 25%;
    width: 7.8rem;
  }

  .error-heading h2::after {
    content: "";
    border-bottom: 2px solid #4b5356;
    position: absolute;
    height: 0.3125rem;
    left: 100%;
    margin-left: 1em;
    top: 25%;
    width: 7.8rem;
  }

  .error-title {
    padding-bottom: 0.25rem;
    line-height: 1.5em;
    font-size: 1.25rem;
    font-weight: 500;
    color: #4b5356;
  }

  .error-text {
    padding-bottom: 0.25rem;
    line-height: 1.5em;
    font-size: 1.15rem;
    font-weight: 400;
    color: #4b5356;
  }

  .goto-home-page-text {
    font-size: 1rem;
    font-weight: 500;
    color: #ed7a20 !important;
    text-decoration: none;
    cursor: pointer;
  }

  .goto-home-page-text:hover {
    opacity: 0.7;
    color: #ed7a20 !important;
  }
}
