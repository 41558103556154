@mixin respond($breakpoint) {
  @if $breakpoint == xs {
    @media (max-width: 400px) {
      @content;
    }
  }

  @if $breakpoint == sm {
    @media (max-width: 600px) {
      @content;
    }
  }

  @if $breakpoint == md {
    @media (max-width: 850px) { @content ; }
  }

  @if $breakpoint == lg {
    @media (min-width: 1100px) { @content ; }
  }
}
