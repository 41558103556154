/* stylelint-disable */
.hero-block {
    padding-bottom: 15px;

    &--background {
        width: 100%;
        height: auto;
        max-width: 100%;
        overflow: hidden;

        img {
            width: 100%;
            height: auto;

            @media screen and (max-width: 575px) {
                width: auto;
                height: 450px;
            }
        }
    }

    &--content {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        color: #FFFFFF;
        transform: translate(-50%, -50%);
    }

    &--title {
        max-width: 460px;
        margin: 0;
    }

    &--logo {
        width: clamp(200px, 20vw, 300px);
        height: clamp(200px, 20vw, 300px);
        border-radius: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;

        @media screen and (min-width: 576px) {
            margin-right: 20px;
        }

        &--image {
            width: clamp(70px, 7.166vw, 110px);
            height: auto;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 1;
        }

        &--text {
            width: 100%;
            height: 100%;
        }

        .circle-text {
            width: 100%;
            height: 100%;
        }
    }
    &-margin-True {
        max-width: 1024px;
        margin: 2em auto;
        position: relative;
    }
}