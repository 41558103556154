/* stylelint-disable */
.cards-panel-block {
    h2 {
        font-size: clamp(15px, 2vw, 32px);
        color: $dark-gray-color;
        margin-bottom: 1.5rem;
        text-align: center;
    }
    .product {
        align-items: center;
    }
    .product__body__title {
        height: initial;
    }
    .product__body__description {
        margin-bottom: 16px;
    }
    .product__menu-link {
        border: 2px solid;
        border-radius: 26px;
        padding: 0.7rem 1.75rem;
    }
}
