/* stylelint-disable */
.contact-page {
  color: $dark-gray-color;

  @include respond(md) {
    padding: 0 25px;
  }

  &__title {
    font-size: 1.2rem;
    max-width: 1024px;
    margin: 0 auto;
  }

  &__text {
    line-height: 3.5rem;
    margin-bottom: 1.25rem;
    max-width: 1024px;
    margin: 0 auto;
  }

  &__form {
    max-width: 1024px;
    margin: 0 auto;

    &__title {
      font-size: 0.9rem;
      max-width: 1024px;
      margin: 0 auto;
    }
  }

  & form {
    @include respond(md) {
      align-items: flex-start !important;
    }
  }
}

.body-menu {
  list-style: none;
  max-width: 1024px;
  margin: 0 auto;

  & li {
    margin-bottom: 20px;
  }
}

.contact-form {
  flex-direction: column;
  position: relative;

  &__group {
    position: relative;
  }

  &__input {
    border-bottom: 1px solid $dark-gray-color;
    margin-bottom: 2.5rem;
    color: $orang-color;
    font-size: 0.8rem;
    resize: none;
  }

  &__label {
    color: $dark-gray-color;
    font-size: 0.8rem;
  }

  &__title {
    font-size: 0.9rem;
  }

  &__label {
    top: -45%;

    @include respond(sm) {
      top: -65%;
    }
  }

  &__input:focus {
    +.form__label {
      color: $orang-color;
      top: -70%;
      font-size: 0.7rem;

      @include respond(sm) {
        top: -90%;
      }
    }
  }

  & textarea {
    border: 1px solid $dark-gray-color;
    padding: 0.8rem;
  }

  & textarea:focus {
    +.form__label {
      top: -45%;
      left: 0;
    }
  }

  .input-focused {
    color: $orang-color;
    top: -70%;

    @include respond(sm) {
      top: -90%;
    }

    font-size: 0.7rem;
    left: 0;
  }

  .textarea-focused {
    left: 0;
    top: -45%;
    color: $orang-color;
  }

  & textarea + label {
    top: -10%;
    left: 30px;
  }

  & button {
    display: inline-block;
    width: 150px;
    margin-bottom: 20px;
  }

  &__error-message {
    position: absolute;
    color: red;
    display: none;
    font-size: 0.7rem;
    bottom: 20px;

    &--textarea {
      top: 80%;
      color: red;
      font-size: 0.7rem;
      position: absolute;
    }
  }
}

.restaurant-list {
  max-height: 100px;
  overflow-y: scroll;
  margin-bottom: 20px;

  &::-webkit-scrollbar {
    width: 0.8em;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $orang-color;
  }
}

.confirmation-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 60px 0 350px;
  text-align: center;
}

.validation-image {
  width: 100px;
  margin-bottom: 20px;
}

.restaurant-list ~ .contact-form__error-message {
  bottom: 2px;
}
