/* stylelint-disable */
.newsletter-container{

}

.newsletter {
  background-color: #555;
  padding: 0 2rem;

  &__content {
    display: flex;
    justify-content: space-between;
    -ms-flex-align: center;
    align-items: center;
    max-width: 1024px;
    min-height: 120px;
    margin: 0 auto;

    &__infoText {
      color: #fff;
      font-size: 1.5rem;
      line-height: 2rem;
      padding: 2rem 2rem 2rem 0;
    }

    @include respond(md) {
      flex-direction: column;
      padding: 2rem 0;
    }
  }
}

form {
  display: flex;

  @include respond(md) {
    flex-direction: column;
    align-items: center;
  }
}

.form {
  position: relative;

  &__group {
    min-width: 26em;
    padding-right: 2rem;
    margin: 2em 0 0;
    position: relative;
    top: 0;

    @include respond(md) {
      min-width: 100%;
    }
  }

  &__input {
    font-size: 14px;
    padding: 0.4em 0;
    display: block;
    width: 100%;
    border: none;
    border-bottom: 1px solid #cecfd0;
    background: none;
    border-radius: 0;
    transition: all 0.3s;
    color: #fff;

    &:focus {
      outline: none;
      border-color: #f57b20;
    }

    &:focus:invalid {
      border-bottom: 1px solid red;
    }

    &::input-placeholder {
      color: #cecfd0;
      font-size: 14px;
    }
  }

  &__label {
    position: absolute;
    font-size: 0.9rem;
    color: #cecfd0;
    display: block;
    transition: all 0.1s;
    top: -100%;
    opacity: 1;
    visibility: visible;
    transform: translateY(40px);
  }

  &__input:focus {
    + .form__label {
      position: absolute;
      color: #cecfd0;
      display: block;
      transition: all 0.1s;
      top: -150%;
      font-size: 0.8rem;
      opacity: 1;
      visibility: visible;
    }
  }

  &__condition {
    padding-top: 2rem;
    padding-bottom: 2rem;
    color: #fff;
    font-size: 0.7rem;
  }

  &__error-message {
    padding: 0;
    height: 2em;
    text-align: left;
    line-height: 1.5em;
    color: #cecfd0;
    font-size: 10px;
    margin-top: 5px;
    display: none;
  }
}

.show-error {
  display: block !important;
  position: absolute;
}

.label-top {
  top: -150%;
  font-size: 0.8rem;
}

.submit-button-container {
  display: flex;
  align-items: center;
  min-width: fit-content;
}
