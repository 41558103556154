.franchises-header {
  position: relative;
  text-align: center;

  &__image {
    height: 350px;
    background-repeat: no-repeat;
    background-position: 50%;
    margin: 0 auto;
  }

  &__title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 3.1em;
    font-weight: 500;
    text-shadow: 0 0 20px rgb(0 0 0 / 70%);

    & p {
      margin: 0;
    }
  }
}

.franchises-body {
  padding-top: 3.8rem;
  display: flexbox;
  display: flex;
  flex-direction: column;
  color: #4b5356;
  max-width: 1024px;
  margin: 0 auto;
}

.full-width {
  width: 100%;
  display: block;
}
