/* stylelint-disable */
.gift-card {
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  color: $dark-gray-color;

  &__container {
    display: flex;
    max-width: 1024px;
    margin: 0 auto;

    @include respond(md) {
      flex-direction: column;
      width: 90%;
      margin: 0 auto;
    }
  }

  h2 {
    text-align: center;
    margin-top: 50px;
    margin-bottom: 20px;
    font-size: 1.4rem;
  }

  .gc-row {
    gap: 50px;
    display: flex;
    flex: 1;

    &:not(:last-child) {
      margin-right: 40px;

      @include respond(md) {
        margin-right: 0;
      }
    }

    @include respond(md) {
      flex-direction: column;
    }
  }

  .gc-col {
    min-height: 250px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex: 1;
  }

  .gift-card-header {
    width: 100%;
  }

  .gift-card-body {
    width: 24rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 0;
    color: $dark-gray-color;

    @include respond(md) {
      padding: 20px 0 30px;
    }

    b {
      font-weight: 600;
      font-size: 1.1rem;
      color: $dark-gray-color;
    }

    h3 {
      font-size: 1.1rem;
      text-align: center;
      font-weight: bold;
    }

    ul {
      margin-top: 15px;
      list-style: none;

      li {
        font-size: 0.8rem;
        margin-bottom: 1em;
        list-style-type: none;
        padding: 0 0 0 4.5em;
        position: relative;
        text-align: left;

        @include respond (md) {
          padding: 0 0 0 2rem;
        }
      }

      li::after {
        content: " ";
        display: block;
        width: 0.3em;
        height: 0.6em;
        border: solid #f57b20;
        border-width: 0 0.2em 0.2em 0;
        position: absolute;
        left: 1em;
        top: 40%;
        margin-top: -0.2em;
        transform: rotate(45deg);
      }
    }

    .button-wrapper {
      text-align: center;
      margin-top: 20px;

      a {
        width: 100%;
      }
    }
  }

  .gc-col {
    @include respond(md) {
      margin-top: 30px;
    }
  }
}

.list-container {
  display: flex;
  flex-direction: column;
}
