/* stylelint-disable */
.footer-wrapper {
  padding: 0 2rem;

  .footer {
    margin: 0 auto;
    max-width: 1024px;
    padding-bottom: 2.75rem;
    color: $dark-gray-color;

    &__column:first-of-type {
      padding-left: 0;
    }

    &__column {
      padding: 1rem;

      @include respond(md) {
        padding: 0;
      }
    }

    &__logo {
      width: 9rem;
      margin-top: 0.5rem;

      @include respond(md) {
        display: none;
      }
    }

    &__link {
      padding: 0.5rem 1rem;
      font-weight: 500;

      @include respond(md) {
        padding-left: 0;
        line-height: 2.5rem;
      }
    }

    &__social-media {
      padding: 0 1rem;

      @include respond(md) {
        text-align: center;
        padding-top: 1.5rem;
        padding-bottom: 2rem;
        font-size: 1rem;
      }

      .social-media-logo {
        margin-left: 15px;
      }
    }

    &__address {
      margin-bottom: 1rem;
      font-style: normal;
      line-height: 20px;
      margin-top: 40px;

      @include respond(md) {
        margin-top: 0;
      }

      @include respond(md) {
        text-align: center;
      }
    }

    &__copyright {
      @include respond(md) {
        text-align: center;
      }
    }
  }

  .footer-content {
    display: flex;
    justify-content: space-between;

    @include respond(md) {
      flex-direction: column;
    }
  }

  .footer__links {
    column-count: 3;
    width: 70%;

    @include respond(md) {
      column-count: 1;
      width: 100%;
    }
  }
}
