/* stylelint-disable */
.express-header {
  position: relative;
  text-align: center;

  &__image {
    height: 300px;
    background-repeat: no-repeat;
    background-position: 50%;
    margin: 0 auto;
  }

  &__title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 3.1em;
    font-weight: 500;
    text-shadow: 0 0 20px rgb(0 0 0 / 70%);

    & p {
      margin: 0;
    }
  }
}

.express-body {
  & li a {
    text-decoration: none;
    color: $dark-gray-color;
  }

  & li.active a {
    color: $orang-color;
  }

  & a {
    text-decoration: underline;
    color: $orang-color;
  }
}

.express-body-content {
  &:not(:last-child) {
    margin-bottom: 30px;
  }

  max-width: 1024px;
  margin: 0 auto;
  margin-top: 30px;
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;

  & ol {
    margin: 30px 0;
    padding-left: 30px;
    font-size: 0.9rem;
  }

  & ul {
    margin-bottom: 40px;
    list-style-type: none;
    display: flex;
    background-color: #fff;
    width: fit-content;
    padding: 10px 5px;
    border-radius: 30px;
    box-shadow: 0 0 1px 0 rgba($dark-gray-color, 0.15), 0 6px 12px 0 rgba($dark-gray-color, 0.15);

    @include respond(sm) {
      flex-direction: column;
      border-radius: 10px;
    }

    & li {
      margin: 0 15px;
      padding: 5px 10px;
      border-radius: 30px;

      @include respond(sm) {
        margin: 10px 15px;
        text-align: center;
      }

      & a {
        margin: 0;
        line-height: 0;
        color: $dark-gray-color;
      }

      cursor: pointer;

      &.active {
        display: inline;
        font-weight: 500;
        background-color: #e6eef9;

        & a {
          color: $orang-color;
        }
      }
    }
  }

  & h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 40px 0 10px;
  }

  &__title {
    font-size: 1.4rem;
    font-weight: 500;
    margin-bottom: 1.25rem;
    color: $color2;
  }

  &__text {
    margin: 0 auto;
  }

  &__text p {
    color: $color2;
    line-height: 35px;
  }

  &__text a {
    line-height: 2.5rem;
    margin-bottom: 1.25rem;

    & a {
      color: $orang-color;
      text-decoration: underline;
    }

    & b {
      font-family: Montserrat-SemiBold, sans-serif;
    }
  }
}

.text-link {
  color: $color3;
  text-decoration: underline;
}
