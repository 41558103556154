/* stylelint-disable */
@import "https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800&display=swap";

@font-face {
  font-family: 'TTRoundsNeue';
  src: url('../fonts/TTRoundsNeue/TTRoundsNeue-Md.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

.Mon400 {
  font-weight: 400;
}

.Mon500 {
  font-weight: 500;
}

.Mon600 {
  font-weight: 600;
}

.Mon700 {
  font-weight: 700;
}

.landing-page-template,
.hero-block,
.image-grid-block,
.video-block,
.promo-block,
.cards-panel-block {
  h1, h2, h3, h4, h5, h6 {
    font-family: 'TTRoundsNeue', sans-serif;
  }
  h1, h2, h3, h4 {
    font-style: normal;
    font-weight: 500;
    text-transform: uppercase;
  }
  h1, h2, h3 {
    font-size: clamp(40px, 4.6875vw, 90px);
    line-height: 100%;
  }
  h4 {
    font-size: clamp(18px, 2.5vw, 40px);
  }
  h5 {
    font-size: clamp(15px, 2vw, 32px);
  }
  h6 {
    font-size: clamp(12px, 1.5vw, 26px);
  }
}
