/* stylelint-disable */
.navigation {
  display: flex;
  justify-content: space-between;
  background-color: $dark-gray-color;

  &__logo {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;

    .social-media-logo {
      margin-left: .8rem;
    }
  }

  &__lang {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
  }
}

.lang-btn {
  background: none;
  border: none;
  color: #fff;
  font-size: 9px;
}

.nav {
  width: 66.66667%;
  font-size: 9px;
  display: flex;
  justify-content: center;

  &__list {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0.5rem 0;
    line-height: 1rem;
    text-transform: uppercase;
    list-style: none;
    width: 100%;
  }

  &__item {
    margin: 0 1.5%;
    color: #fff;
  }
}

.sticky + .white-space {
  margin-top: 87px;
}

.sticky + .white-space--mobile {
  margin-top: 70px;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  background: #fff;
  z-index: 199;
  margin: 0;
}

.navigation-menu {
  .second-nav {
    padding-top: 35px;
    display: flex;
    justify-content: center;
    align-items: center;

    &__list {
      display: flex;
      justify-content: center;
      list-style: none;
      width: 100%;
      margin-bottom: 15px;
    }

    &__item {
      margin: 0 1.5%;
      display: flex;
      align-items: center;
      font-size: 1rem;
    }

    &__link {
      font-weight: 500;
    }
  }
}

/* Mobile navigation */

.mob-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  color: #fff;
  height: 70px;
  padding: 1em;

  &__logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  &__logo {
    height: 35px;
  }

  &__social-logo-fb img {
    margin-right: 20px;
  }
  &__social-logo-insta img {
    margin-right: 20px;
  }

  &__social-logo-fb,
  &__social-logo-tiktok,
  &__social-logo-insta {
    & img {
      height: 32px;
    }
  }
}

.menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f5f5f2;
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 70px;
  width: 100%;

  &__nav-1 {
    color: #f57b20 !important;

    & button {
      color: #f57b20;
      font-size: 1rem;
    }
  }

  &__nav-2 {
    color: $dark-gray-color;
  }
}

.menu > li {
  overflow: hidden;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0.5em 0;
  width: 100%;
  font-size: 1.2rem;
}

.menu-button-container {
  display: flex;
  height: 100%;
  width: 30px;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#menu-toggle {
  display: none;
}

.menu-button,
.menu-button::before,
.menu-button::after {
  display: block;
  background-color: #f57b20;
  position: absolute;
  height: 2px;
  width: 30px;
  transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
  border-radius: 2px;
}

.menu-button::before {
  content: "";
  margin-top: -8px;
}

.menu-button::after {
  content: "";
  margin-top: 8px;
}

#menu-toggle:checked + .menu-button-container .menu-button::before {
  margin-top: 0;
  transform: rotate(405deg);
}

#menu-toggle:checked + .menu-button-container .menu-button {
  background: rgb(255 255 255 / 0%);
}

#menu-toggle:checked + .menu-button-container .menu-button::after {
  margin-top: 0;
  transform: rotate(-405deg);
}

#menu-toggle ~ .menu li {
  height: 0;
  margin: 0;
  padding: 0;
  border: 0;
  transition: height 0.4s cubic-bezier(0.23, 1, 0.32, 1);
}

#menu-toggle:checked ~ .menu {
  height: calc(100vh - 70px);
  z-index: 3;
  overflow-y: scroll;
}

#menu-toggle:checked ~ .menu li {
  min-height: 3em;
  padding: 0.5em;
  align-items: center;
  transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
}

.menu > li:not(:last-child) {
  border-bottom: 1px solid #444;
}
